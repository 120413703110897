import React, {Fragment} from 'react';
import {Button, ButtonGroup, Divider, Typography, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import {BugReport as BugIcon, Feedback as FeedbackIcon} from '@material-ui/icons';
import {EmailTemplateService} from '../../../../services/emailTemplate/emailTempalte';
import {NavLink} from 'react-router-dom';


function Footer(props) {
  const {classes, className, t} = props;

  const rootClassName = classNames(classes.root, className);

  const openFeedbackMail = () => {
    window.location.href = EmailTemplateService.GetFeedbackEmailToString();
  };

  const openBugMail = () => {
    window.location.href = EmailTemplateService.GetBugMailToString();
  };

  return (
    <Fragment>
      <div className={rootClassName}>
        <Divider/>
        <div className={classes.infosLeft}>
          <div className={classes.legalButtons}>
            <Button
              color="inherit"
              component={NavLink}
              disableElevation
              to={process.env.PUBLIC_URL + '/impressum'}
            >
              Impressum
            </Button>
            <Button
              color="inherit"
              component={NavLink}
              disableElevation
              to={process.env.PUBLIC_URL + '/privacy'}
            >
              Datenschutz
            </Button>
          </div>
          <Typography
            className={classes.copyRight}
            variant="body1"
          >
            Copyright &copy; Zukunftsangelegenheiten GmbH
          </Typography>
        </div>
      </div>
      <ButtonGroup
        className={classes.feedbackButtons}
        color="secondary"
        variant="contained"
      >
        <Button
          onClick={openFeedbackMail}
          title={t('reportFeedback')}
        >
          <FeedbackIcon/>
        </Button>
        <Button
          onClick={openBugMail}
          title={t('reportBug')}
        >
          <BugIcon/>
        </Button>
      </ButtonGroup>
    </Fragment>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(withStyles(styles), withTranslation())(Footer);
