export default theme => ({
  root: {},
  gridItem: {
    padding: theme.spacing(0),
    minWidth: '650px',
    minHeight: 'calc(-195px + 100vh)',
  },
  templateTable: {
    marginBottom: theme.spacing(2),
  },
  button: {
    float: 'right',
    marginLeft: theme.spacing(1)
  },
  redButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
      color: theme.palette.common.white,
    }
  },
  displayCustomersCheckbox: {
    float: 'right',
    marginTop: theme.spacing(-4),
    marginLeft: theme.spacing(2),
  },
  customerMarkerIcon: {
    marginBottom: '-3px',
    width: '24px',
    height: '24px',
    color: theme.palette.common.black,
  }
});
