import React, {useState} from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {InfoIcon, Portlet, PortletContent} from '../../../../index';
import Typography from '@material-ui/core/Typography';
import {Cancel as CancelIcon, DeleteForever as DeleteForeverIcon, Save as SaveIcon} from '@material-ui/icons';
import classNames from 'classnames';
import moment from 'moment';
import {EmptiesPaper, SchedulerConfirmationDialog, TrackingLinkInfoEdit, TrackingNotificationsEdit} from '../../index';
import {CreateAddress, CreatePerson} from '../../../Shared';
import {containsInjectionChars} from '../../../../../services/util/helperFuncs';
import StopBonusEdit from '../../StopBonus/Edit';

function EditShipper(props) {
  const {classes, t, shipper, saveClick, cancelClick, deleteClick} = props;

  const [schedulerConfirmationDialogState, setSchedulerConfirmationDialogState] = useState(false);

  const [info, setInfo] = useState({
    name: shipper.name,
    shortName: shipper.shortName,
    signature: shipper.signature,
    showEmergencyContact: shipper.showEmergencyContact,
  });

  const [deadlines, setDeadlines] = useState({
    previewDeadline: (shipper.previewDeadline - (new Date().getTimezoneOffset() * 60)) / (60 * 60),
    finalDeadline: (shipper.finalDeadline - (new Date().getTimezoneOffset() * 60)) / (60 * 60),
  });

  const [empties, setEmpties] = useState(shipper.empties);

  const [address, setAddress] = useState({
    street: shipper.address.street,
    houseNumber: shipper.address.houseNumber,
    zipcode: shipper.address.zipcode,
    city: shipper.address.city,
    country: shipper.address.country,
  });

  const [billingAddress, setbillingAddress] = useState({
    street: shipper.billingAddress.street,
    houseNumber: shipper.billingAddress.houseNumber,
    zipcode: shipper.billingAddress.zipcode,
    city: shipper.billingAddress.city,
    country: shipper.billingAddress.country,
  });

  const [contact, setContact] = useState({
    name: shipper.contact.name,
    email: shipper.contact.email,
    phoneNumber: shipper.contact.phoneNumber,
  });

  const [emergencyContact, setEmergencyContact] = useState({
    name: shipper?.emergencyContact?.name || '-',
    email: shipper?.emergencyContact?.email || '-',
    phoneNumber: shipper?.emergencyContact?.phoneNumber || '-',
  });

  const [serviceContact, setServiceContact] = useState({
    name: shipper?.serviceContact?.name || '-',
    email: shipper?.serviceContact?.email || '-',
    phoneNumber: shipper?.serviceContact?.phoneNumber || '-',
  });

  const [disponent, setDisponent] = useState({
    name: shipper?.disponent.name,
    email: shipper?.disponent.email,
    phoneNumber: shipper?.disponent.phoneNumber,
  });

  const [trackingNotifications, setTrackingNotifications] = useState({
    emailNotificationsDefaultValue: shipper?.trackingNotifications?.emailNotificationsDefaultValue || false,
    senderEmail: shipper?.trackingNotifications?.senderEmail || '',
    previewStopUploadedNotifications: shipper?.trackingNotifications?.previewStopUploadedNotifications || false,
    previewDeadlineExpiredNotifications: shipper?.trackingNotifications?.previewDeadlineExpiredNotifications || false,
    finalDeadlineExpiredNotifications: shipper?.trackingNotifications?.finalDeadlineExpiredNotifications || false,
    tourApprovedNotifications: shipper?.trackingNotifications?.tourApprovedNotifications || false,
    tourStartedNotifications: shipper?.trackingNotifications?.tourStartedNotifications || false,
    previousStopsDeliveredNotifications: shipper?.trackingNotifications?.previousStopsDeliveredNotifications || false,
    deliveredNotifications: shipper?.trackingNotifications?.deliveredNotifications || false,
    problemNotifications: shipper?.trackingNotifications?.problemNotifications || false,
    problemNotificationsForShipper: shipper?.trackingNotifications?.problemNotificationsForShipper || false,
    problemNotificationText: shipper?.trackingNotifications?.problemNotificationText || '',
  });

  const [trackingLinkInfos, setTrackingLinkInfos] = useState({
    logoName: shipper?.trackingLinkInfos?.logoName || '',
    marketingImageName: shipper?.trackingLinkInfos?.marketingImageName || '',
    primaryColor: shipper?.trackingLinkInfos?.primaryColor || '',
    primaryTextColor: shipper?.trackingLinkInfos?.primaryTextColor || '',
    primaryContrastTextColor: shipper?.trackingLinkInfos?.primaryContrastTextColor || '',
    secondaryColor: shipper?.trackingLinkInfos?.secondaryColor || '',
    secondaryTextColor: shipper?.trackingLinkInfos?.secondaryTextColor || '',
  });

  const [stopBonus, setStopBonus] = useState({
    enabled: shipper?.stopBonus?.enabled || false,
    threshold: shipper?.stopBonus?.threshold || 0,
    divider: shipper?.stopBonus?.divider || 0
  });

  const activateOk = () => {
    return info.name &&
      address.street && address.houseNumber && address.zipcode && address.city && address.country &&
      billingAddress.street && billingAddress.houseNumber && billingAddress.zipcode && billingAddress.city && billingAddress.country &&
      contact.email && contact.phoneNumber &&
      !isNaN(deadlines.previewDeadline) && !isNaN(deadlines.finalDeadline) && parseFloat(deadlines.previewDeadline) <= parseFloat(deadlines.finalDeadline) &&
      trackingNotifications.senderEmail.split('@')[0] && trackingNotifications.senderEmail.split('@')[1] === 'grünestadtlogistik.com' &&
      !containsInjectionChars(trackingLinkInfos.logoName) && !containsInjectionChars(trackingLinkInfos.marketingImageName) && !containsInjectionChars(trackingLinkInfos.primaryColor) &&
      !containsInjectionChars(trackingLinkInfos.primaryTextColor) && !containsInjectionChars(trackingLinkInfos.primaryContrastTextColor) && !containsInjectionChars(trackingLinkInfos.secondaryColor) &&
      !containsInjectionChars(trackingLinkInfos.secondaryTextColor);
  };

  const evaluateSave = () => {
    const timeZoneOffset = new Date().getTimezoneOffset() * 60;
    const deadlineChanged = shipper.previewDeadline !== Math.round((deadlines.previewDeadline * 60 * 60) + timeZoneOffset) ||
      shipper.finalDeadline !== Math.round((deadlines.finalDeadline * 60 * 60) + timeZoneOffset);
    return deadlineChanged ? setSchedulerConfirmationDialogState(true) : save();
  }

  const save = () => {
    setSchedulerConfirmationDialogState(false);
    saveClick(info, empties, address, billingAddress, contact, serviceContact, emergencyContact, disponent, deadlines, trackingNotifications, trackingLinkInfos, stopBonus);
  }


  const previewDeadlineTime = moment().startOf('day').seconds(deadlines.previewDeadline * 3600);
  const finalDeadlineTime = moment().startOf('day').seconds(deadlines.finalDeadline * 3600);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <Button
            className={classes.button}
            color="default"
            onClick={cancelClick}
            title={t('cancel')}
            variant="contained"
          >
            <CancelIcon/>&nbsp;{t('cancel')}
          </Button>
          <Button
            className={classNames(classes.button, classes.deleteButton)}
            color="primary"
            onClick={
              () => {
                if (window.confirm(t('confirmDeleteShipper'))) {
                  deleteClick()
                }
              }
            }
            title={t('deleteShipper')}
            variant="contained"
          >
            <DeleteForeverIcon/>
          </Button>
          <Button
            className={classes.button}
            color="primary"
            disabled={!activateOk()}
            onClick={evaluateSave}
            title={t('save')}
            variant="contained"
          >
            <SaveIcon/>
          </Button>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Portlet className={classes.content}>
            <PortletContent>
              <Typography variant="h3">
                {t('info')}
              </Typography>
              <hr/>
              <Typography variant="body1">
                {t('empties')}
              </Typography>
              <EmptiesPaper
                empties={empties}
                setEmpties={setEmpties}
              />
              <FormControl className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={info.signature}
                        color="primary"
                        onChange={(event) => {
                          setInfo({...info, signature: event.target.checked})
                        }}
                        value={info.signature}
                      />
                    }
                    label={t('signature')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={info.showEmergencyContact}
                        color="primary"
                        onChange={(event) => {
                          setInfo({...info, showEmergencyContact: event.target.checked})
                        }}
                        value={info.showEmergencyContact}
                      />
                    }
                    label={t('showEmergencyContact')}
                  />
                </FormGroup>
              </FormControl>
            </PortletContent>
          </Portlet>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreateAddress
            address={address}
            className={classes.content}
            required
            setAddress={setAddress}
            title={t('address')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreateAddress
            address={billingAddress}
            className={classes.content}
            required
            setAddress={setbillingAddress}
            title={t('billingAddress')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreatePerson
            className={classes.content}
            person={contact}
            required
            setPerson={setContact}
            title={t('bossContact')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreatePerson
            className={classes.content}
            person={emergencyContact}
            setPerson={setEmergencyContact}
            title={t('emergencyContact')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreatePerson
            className={classes.content}
            person={serviceContact}
            setPerson={setServiceContact}
            title={t('serviceContact')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreatePerson
            className={classes.content}
            person={disponent}
            setPerson={setDisponent}
            title={t('disponent')}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Portlet className={classes.content}>
            <PortletContent>
              <div className={classes.iconWrapper}>
                <Typography variant="h3">
                  {t('deadlines')}
                </Typography>
                <InfoIcon
                  className={classes.iconMargin}
                  descriptionKey={'32'}
                />
              </div>
              <hr/>
              <FormControl className={classes.formControl}>
                <InputLabel
                  htmlFor="previewDeadline"
                  required
                >{t('previewDeadline')}</InputLabel>
                <Input
                  error={isNaN(deadlines.previewDeadline) || parseFloat(deadlines.previewDeadline) > parseFloat(deadlines.finalDeadline)}
                  onChange={(event) => {
                    setDeadlines({
                      ...deadlines,
                      previewDeadline: event.target.value
                    });
                  }}
                  onClick={event => event.target.select()}
                  onWheel={event => event.target.blur()}
                  type="number"
                  value={deadlines.previewDeadline}
                />
                <Typography variant="body1">
                  {`(${t('pointInTime')}: ${(previewDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days') < 0 ?
                    't' : 't+') + previewDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days')} 
                  ${previewDeadlineTime.format('HH:mm')})`}
                </Typography>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel
                  htmlFor="finalDeadline"
                  required
                >{t('finalDeadline')}</InputLabel>
                <Input
                  error={isNaN(deadlines.finalDeadline) || parseFloat(deadlines.previewDeadline) > parseFloat(deadlines.finalDeadline)}
                  onChange={(event) => {
                    setDeadlines({
                      ...deadlines,
                      finalDeadline: event.target.value
                    })
                  }}
                  onClick={event => event.target.select()}
                  onWheel={event => event.target.blur()}
                  type="number"
                  value={deadlines.finalDeadline}
                />
                <Typography variant="body1">
                  {`(${t('pointInTime')}: ${(finalDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days') < 0 ?
                    't' : 't+') + finalDeadlineTime.clone().startOf('day').diff(moment().startOf('day'), 'days')} 
                  ${finalDeadlineTime.format('HH:mm')})`}
                </Typography>
              </FormControl>
            </PortletContent>
          </Portlet>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TrackingNotificationsEdit
            className={classes.content}
            setTrackingNotifications={setTrackingNotifications}
            trackingNotifications={trackingNotifications}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TrackingLinkInfoEdit
            className={classes.content}
            setTrackingLinkInfos={setTrackingLinkInfos}
            trackingLinkInfos={trackingLinkInfos}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <StopBonusEdit
            className={classes.content}
            setStopBonus={setStopBonus}
            stopBonus={stopBonus}
          />
        </Grid>
      </Grid>
      <SchedulerConfirmationDialog
        handleCancel={() => setSchedulerConfirmationDialogState(false)}
        handleClose={save}
        schedulerConfirmationDialogState={schedulerConfirmationDialogState}
      />
    </React.Fragment>
  );
}


EditShipper.propTypes = {
  cancelClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  deleteClick: PropTypes.func.isRequired,
  saveClick: PropTypes.func.isRequired,
  shipper: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(EditShipper);
