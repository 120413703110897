import React, {forwardRef, useState} from 'react';
import classNames from 'classnames'
import {NavLink} from 'react-router-dom'
import {Collapse, Divider, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import styles from './styles'

import {
  AccessAlarmOutlined as FinalDeadlineMigrationIcon,
  AccessTime as TimeOverviewIcon,
  ArtTrackOutlined as DispositionIcon,
  AssignmentIndOutlined as BaseDataIcon,
  AssignmentOutlined as OverviewsIcons,
  BuildOutlined as SimulateTourBuildIcon,
  CalendarTodayOutlined as SpecialDeliveryDaysIcon,
  DashboardOutlined as DashboardIcon,
  DeleteOutline as DeletedStopsIcon,
  Equalizer as StatisticsOverviewIcon,
  ExpandLess,
  ExpandMore,
  FaceOutlined as DriversIcon,
  FlagOutlined as StopOverview,
  GetApp as DownloadAppIcon,
  LowPriority as TourTemplatesIcon,
  MapOutlined as TourOverviewIcon,
  MapOutlined as MapDispositionIcon,
  MotorcycleOutlined as VehiclesIcon,
  PersonOutline as ProfileIcon,
  PersonPinOutlined as CustomerCatalogIcon,
  Pets as MicroHubAdministrationIcon,
  Pets as MicroHubOverviewIcon,
  SettingsOutlined as AdministrationIcon,
  SettingsOverscanOutlined as AreaSettings,
  SystemUpdateAltOutlined as UploadIcon,
  VisibilityOutlined as PreviewToursIcon,
} from '@material-ui/icons'
import {withStyles} from '@material-ui/styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';

const linksInGroups = {
  dashboard: '/dashboard',
  overview: {
    microHubOverView: '/microHubOverview',
    tourOverview: '/tour-overview',
    stopOverview: '/stop-overview',
    statisticsOverview: '/statistics-overview',
  },
  disposition: {
    tourTemplates: '/tourTemplates',
    previewTours: '/previewTours',
    finalDeadlineMigration: '/finalDeadlineMigration',
    timeDisposition: '/time_disposition',
    mapDisposition: '/map_disposition',
  },
  administration: {
    simulateTourBuilding: '/simulateTourBuilding',
    dataUpload: '/dataUpload',
    vehicles: '/vehicles',
    drivers: '/drivers',
    specialDeliveryDays: '/specialDeliveryDays',
    microHubAdministration: '/microHubAdministration',
    deletedStops: '/deletedStops',
    customerCatalog: '/customerCatalog',
    downloadApp: '/get-app'
  },
  baseData: {
    profile: '/profile',
    areaSelection: '/area'
  },
};

function Sidebar(props) {

  const {classes, className, t} = props;
  const [openAdministration, setOpenAdministration] = useState(false);
  const [openDisposition, setOpenDisposition] = useState(false);
  const [openBaseData, setOpenBaseData] = useState(false);
  const [openOverview, setOpenOverview] = useState(false);
  const rootClassName = classNames(classes.root, className);

  if (Object.getOwnPropertyNames(linksInGroups.overview).some(property => linksInGroups.overview[property] === window.location.href.substring(window.location.href.lastIndexOf('/')))) {
    if (!openOverview) setOpenOverview(true);
  }
  if (Object.getOwnPropertyNames(linksInGroups.disposition).some(property => linksInGroups.disposition[property] === window.location.href.substring(window.location.href.lastIndexOf('/')))) {
    if (!openDisposition) setOpenDisposition(true);
  }
  if (Object.getOwnPropertyNames(linksInGroups.administration).some(property => linksInGroups.administration[property] === window.location.href.substring(window.location.href.lastIndexOf('/')))) {
    if (!openAdministration) setOpenAdministration(true);
  }
  if (Object.getOwnPropertyNames(linksInGroups.baseData).some(property => linksInGroups.baseData[property] === window.location.href.substring(window.location.href.lastIndexOf('/')))) {
    if (!openBaseData) setOpenBaseData(true);
  }

  const MyNavLink = forwardRef((props, ref) => (
    <NavLink
      innerRef={ref}
      {...props}
    />
  ));

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>

        <img
          alt=""
          height="54px"
          src="/images/logo100px.png"
        />

      </div>
      <Divider className={classes.logoDivider}/>
      <List
        component="div"
        disablePadding
      >
        <ListItem
          activeClassName={classes.activeListItem}
          className={classes.listItem}
          component={MyNavLink}
          to={linksInGroups.dashboard}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DashboardIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('dashboard')}
          />
        </ListItem>
        {/*##########################################################Overview#####################################################################*/}
        <ListItem
          button
          className={classes.menuHeader}
          onClick={() => setOpenOverview(!openOverview)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <OverviewsIcons/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('overviews')}
          />
          {openOverview ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse
          in={openOverview}
          timeout="auto"
        >
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overview.tourOverview}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <TourOverviewIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('tourOverview')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overview.microHubOverView}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <MicroHubOverviewIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('microHubOverview')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.specialDeliveryDays}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SpecialDeliveryDaysIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('specialDeliveryDays')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overview.stopOverview}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <StopOverview/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('stopOverview')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overview.statisticsOverview}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <StatisticsOverviewIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('statisticsOverview')}
              />
            </ListItem>
          </List>
        </Collapse>
        {/*##########################################################Disposition##################################################################*/}
        <ListItem
          button
          className={classes.menuHeader}
          onClick={() => setOpenDisposition(!openDisposition)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DispositionIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('disposition')}
          />
          {openDisposition ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse
          in={openDisposition}
          timeout="auto"
        >
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.disposition.tourTemplates}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <TourTemplatesIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('tourTemplates')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.disposition.previewTours}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <PreviewToursIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('previewTours')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.disposition.finalDeadlineMigration}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <FinalDeadlineMigrationIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('finalDeadlineMigration')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.disposition.mapDisposition}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <MapDispositionIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('mapDisposition')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.disposition.timeDisposition}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <TimeOverviewIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('timeDisposition')}
              />
            </ListItem>
          </List>
        </Collapse>
        {/*##########################################################Administration##################################################################*/}
        <ListItem
          button
          className={classes.menuHeader}
          onClick={() => setOpenAdministration(!openAdministration)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <AdministrationIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('administrationRider')}
          />
          {openAdministration ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse
          in={openAdministration}
          timeout="auto"
        >
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.drivers}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <DriversIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('drivers')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.vehicles}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <VehiclesIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('vehicles')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.microHubAdministration}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <MicroHubAdministrationIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('microHubAdministration')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.customerCatalog}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <CustomerCatalogIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('customerCatalog')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.dataUpload}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <UploadIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('dataUpload')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.simulateTourBuilding}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SimulateTourBuildIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('simulateTourBuilding')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.deletedStops}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <DeletedStopsIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('deletedStops')}
              />
            </ListItem>
          </List>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classNames(classes.listItem, classes.nested)}
            component={MyNavLink}
            to={linksInGroups.administration.downloadApp}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DownloadAppIcon/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              primary={t('appDownload')}
            />
          </ListItem>
        </Collapse>
        {/*##########################################################BaseData##################################################################*/}
        <ListItem
          button
          className={classes.menuHeader}
          onClick={() => setOpenBaseData(!openBaseData)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <BaseDataIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('account')}
          />
          {openBaseData ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse
          in={openBaseData}
          timeout="auto"
        >
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.baseData.profile}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ProfileIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('profile')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.baseData.areaSelection}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AreaSettings/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('areaSelection')}
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </nav>
  );
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(Sidebar);
