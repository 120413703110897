import {gql} from 'apollo-boost';

export class ShipperQueryService {

  static getShipperProfileQuery(shipper) {
    return gql`
    {
      shippers(shipperName: "${shipper}") {
        name
        shortName
        showEmergencyContact
        previewDeadline
        finalDeadline
        address {
          city
          country
          zipcode
          houseNumber
          street
        }
        billingAddress {
          city
          country
          zipcode
          houseNumber
          street
        }
        contact {
          email
          name
          phoneNumber
        }
        empties {
          id
          name
          order
        }
        emergencyContact {
          email
          name
          phoneNumber
        }
        serviceContact {
          email
          name
          phoneNumber
        }
        disponent {
          email
          name
          phoneNumber
        }
        signature
        trackingLinkInfos {
          logoName
          marketingImageName
          primaryColor
          primaryTextColor
          primaryContrastTextColor
          secondaryColor
          secondaryTextColor
        }
        trackingNotifications {
          emailNotificationsDefaultValue
          senderEmail
          deliveredNotifications
          finalDeadlineExpiredNotifications
          previewStopUploadedNotifications
          previewDeadlineExpiredNotifications
          previousStopsDeliveredNotifications
          tourApprovedNotifications
          tourStartedNotifications
          problemNotifications
          problemNotificationsForShipper
          problemNotificationText
        }
        stopBonus {
          enabled
          threshold
          divider
        }
      }
    }
    `;
  }

  static getAllShipperEmptiesQuery() {
    return gql`
    {
      shippers {
        id
        name
        shortName
        empties {
          id
          name
          order
        }
      }
    }
    `;
  }

  static getAllShipperQuery() {
    return gql`
    {
      shippers {
        id
        name
        shortName
        showEmergencyContact
        previewDeadline
        finalDeadline
        address {
          city
          country
          zipcode
          houseNumber
          street
        }
        billingAddress {
          city
          country
          zipcode
          houseNumber
          street
        }
        contact {
          email
          name
          phoneNumber
        }
        empties {
          id
          name
          order
        }
        emergencyContact {
          email
          name
          phoneNumber
        }
        serviceContact {
          email
          name
          phoneNumber
        }
        disponent {
          email
          name
          phoneNumber
        }
        signature
        trackingLinkInfos {
          logoName
          marketingImageName
          primaryColor
          primaryTextColor
          primaryContrastTextColor
          secondaryColor
          secondaryTextColor
        }
        trackingNotifications {
          emailNotificationsDefaultValue
          senderEmail
          deliveredNotifications
          finalDeadlineExpiredNotifications
          previewStopUploadedNotifications
          previewDeadlineExpiredNotifications
          previousStopsDeliveredNotifications
          tourApprovedNotifications
          tourStartedNotifications
          problemNotifications
          problemNotificationsForShipper
          problemNotificationText
        }
        stopBonus {
          enabled
          threshold
          divider
        }
      }
    }
    `;
  }

  static getAllShipperNames() {
    return gql`
    {
      shippers {
        name
        shortName
      }
    }
    `;
  }

  static updateShipperById() {
    return gql`
      mutation($shipper: ShipperInputType!) {
        updateShipper(shipper: $shipper) {
          name
        }
      }
    `;
  }

  static deleteShipperById() {
    return gql`
      mutation ($shipperId: Int!) {
        deleteShipper(shipperId: $shipperId)
      }
    `;
  }

  static createShipper() {
    return gql`
      mutation($shipper: ShipperInputType!) {
        createShipper(shipper: $shipper) {
          name
        }
      }
    `;
  }


  static getBoxTypesByShipperNameAndIdentifierList(shipperName, identifierList) {
    const startSymbol = identifierList && identifierList.length > 0 ? '"' : '';
    return gql`
     {
      boxTypes(shipperName: "${shipperName}", identifiers: [${startSymbol}${identifierList.join('", "')}${startSymbol}]) {
        boxTypeId
        depth
        height
        identifier
        kind
        stackable
        volume
        width
        description
      }
    }
    `;
  }

  static deleteBoxTypeByIdIdentifier() {
    return gql`
       mutation ($identifier: String!) {
        deleteBoxTypeByIdentifier(identifier: $identifier)
      }
    `;
  }

  static updateBoxTypeByIdentifierQuery() {
    return gql`
      mutation ($identifier: String!, $updatedBoxType: BoxTypeInputType!) {
        updateBoxTypeByIdentifier(identifier: $identifier, updatedBoxType: $updatedBoxType) {
          boxTypeId
          depth
          height
          identifier
          kind
          stackable
          volume
          width
          description
        }
      }
    `;
  }

  static createBoxTypeQuery() {
    return gql`
      mutation ($shipperName: String! $boxTypeToCreate: BoxTypeInputType!) {
        createBoxTypeForShipper(shipperName: $shipperName, toCreate: $boxTypeToCreate) {
          boxTypeId
          depth
          height
          identifier
          kind
          stackable
          volume
          width
          description
        }
      }
    `;
  }

  static getShipperWithBoxTypesQuery(shipperName) {
    return gql`
    {
      shippers(shipperName: "${shipperName}") {
        name
        shortName
        boxTypes {
          boxTypeId
          depth
          height
          identifier
          kind
          stackable
          volume
          width
          description
        }
      }
    }
    `;
  }

  static getShipperDeadlinesByShipperNameQuery(shipperName) {
    return gql`
    {
      shippers(shipperName: "${shipperName}") {
        id
        name
        shortName
        previewDeadline
        finalDeadline
       }
    }
    `;
  }

  static getAllShipperDeadlines() {
    return gql`
    {
      shippers {
        id
        name
        shortName
        previewDeadline
        finalDeadline
       }
    }
    `;
  }

}
