import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Grid, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {
  DashboardFilter,
  DeadlineCard,
  DriverInfoCard,
  GeneralInfoIcon,
  KPICard,
  MicroHubUtilizationCard,
  ProblemNotificationCard,
  RefreshButton,
  StopAndTourCard,
  TimeDispositionCard,
  ToursInDeliveryCard,
} from '../../../components';
import {ShipperQueryService} from '../../../services/backend/shipperQueryService';
import {useQuery} from '@apollo/react-hooks';
import {ClientService} from '../../../services/client/clientService';
import {clientTypes} from '../../../services/client/clientTypes';
import {displayModes} from '../../../services/enums/displayModes';
import {TourService} from '../../../services/backend/tourService';
import {MicroHubQueryService} from '../../../services/backend/microHubQueryService';
import {AuthService} from '../../../services/auth/authService';
import {useStopHub} from '../../../hooks/useStopHub';
import {messageRelevantForDashboardFilter} from '../../../services/util/signalRMessageHelper';
import {useTourHub} from '../../../hooks/useTourHub';
import moment from 'moment';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {DatePickerDefaultValueContext} from '../../../context';
import useMicroHubOptionsOfCarrier from '../../../hooks/useMicroHubOptionsOfCarrier';

const clientShipper = ClientService.getClient(clientTypes.shipper);
const clientMicroHub = ClientService.getClient(clientTypes.microHub);

function Dashboard(props) {

  const {classes, t} = props;
  const {fromDateDefault, toDateDefault, updateDefaultDate} = useContext(DatePickerDefaultValueContext);

  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState({
    carrierName: AuthService.getUserOrganization(),
    shipperName: '',
    microHubName: '',
    fromDate: fromDateDefault,
    toDate: toDateDefault
  });
  useEffect(() => updateDefaultDate(filter.fromDate), [filter.fromDate, updateDefaultDate]);
  const [backendLoadingTours, setBackendLoadingTours] = useState(false);
  const [backendLoadingStops, setBackendLoadingStops] = useState(false);
  const [stops, setStops] = useState([]);
  const [tours, setTours] = useState([]);
  const [kpiDate, setKpiDate] = useState(moment().startOf('day').toDate());
  const [kpiReferenceDate, setKpiReferenceDate] = useState({
    from: moment().subtract(1, 'month').startOf('month').toDate(),
    to: moment().subtract(1, 'month').endOf('month').toDate()
  });
  const [backendLoadingKpisForDate, setBackendLoadingKpisForDate] = useState(false);
  const [backendLoadingKpisReference, setBackendLoadingKpisReference] = useState(false);
  const [kpiDataStopsPerHourReference, setKpiDataStopsPerHourReference] = useState(null);
  const [kpiDataStopsPerHourForDate, setKpiDataStopsPerHourForDate] = useState(null);
  const [kpiDataTourPackTimeReference, setKpiDataTourPackTimeReference] = useState(null);
  const [kpiDataTourPackTimeForDate, setKpiDataTourPackTimeForDate] = useState(null);
  const [driverNotificationsData, setDriverNotificationsData] = useState([]);
  const [backendLoadingDriverNotifications, setBackendLoadingDriverNotifications] = useState(false);

  const [problemNotifications, setProblemNotifications] = useState([]);
  const [backendLoadingProblemNotifications, setBackendLoadingProblemNotifications] = useState(false);

  const {microHubOptions, microHubData} = useMicroHubOptionsOfCarrier(AuthService.getUserOrganization(), true);
  const {shipperOptions} = useShipperOptions();

  useStopHub(useCallback(event => {
    if (messageRelevantForDashboardFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  useTourHub(useCallback(event => {
    if (messageRelevantForDashboardFilter(event, filter)) {
      setRefresh(true);
    }
  }, [filter]));

  const shipperDeadlineQuery = ShipperQueryService.getAllShipperDeadlines();
  const {loading: loadingShipperDeadlines, error: errorShipperDeadlines, data: dataShipperDeadlines} = useQuery(shipperDeadlineQuery, {client: clientShipper});

  const microHubPlanQuery = MicroHubQueryService.getMicroHubPlansByCarrierNameAndMicroHubNameQuery(filter.carrierName, filter.microHubName);
  const {error: errorMicroHubPlans, loading: loadingMicroHubPlans, data: dataMicroHubPlans} = useQuery(microHubPlanQuery, {
    client: clientMicroHub,
    skip: !filter.carrierName || !filter.microHubName
  });

  const loadData = useCallback(() => {
    setBackendLoadingTours(true);
    setBackendLoadingStops(true);
    TourService.getToursWithFilter(filter.carrierName, filter.shipperName, filter.microHubName, filter.fromDate, filter.toDate).then(response => response.json()).then(response => {
      setTours(response);
      setBackendLoadingTours(false);
      setRefresh(false);
    }, () => {
      alert(t('errorLoadingTours'));
      setBackendLoadingTours(false);
      setRefresh(false);
    });
    TourService.getStopsWithFilter(filter.carrierName, filter.shipperName, filter.microHubName, null, filter.fromDate, filter.toDate)
      .then(response => response.json())
      .then(response => {
        setStops(response);
        setBackendLoadingStops(false);
        setRefresh(false);
      }, () => {
        alert(t('errorLoadingStops'));
        setBackendLoadingStops(false);
        setRefresh(false);
      });
  }, [filter, t]);

  const loadKpiDataReference = useCallback(() => {
    setBackendLoadingKpisReference(true);
    TourService.getTimeToPackSecondsPerBox(kpiReferenceDate.from, kpiReferenceDate.to, filter.carrierName)
      .then(response => response.json())
      .then(response => {
        setKpiDataTourPackTimeReference(response);
        setBackendLoadingKpisReference(false);
      }, () => {
        alert(t('errorLoadingKpiData'));
        setBackendLoadingKpisReference(false);
      });
    TourService.getStopsPerHour(kpiReferenceDate.from, kpiReferenceDate.to, filter.carrierName)
      .then(response => response.json())
      .then(response => {
        setKpiDataStopsPerHourReference(response);
        setBackendLoadingKpisReference(false);
      }, () => {
        alert(t('errorLoadingKpiData'));
        setBackendLoadingKpisReference(false);
      });
  }, [filter, kpiReferenceDate, t]);

  const loadKpiDataForDate = useCallback(() => {
    setBackendLoadingKpisForDate(true);
    TourService.getTimeToPackSecondsPerBox(kpiDate, moment(kpiDate).endOf('day').toDate(), filter.carrierName)
      .then(response => response.json())
      .then(response => {
        setKpiDataTourPackTimeForDate(response);
        setBackendLoadingKpisForDate(false);
      }, () => {
        alert(t('errorLoadingKpiData'));
        setBackendLoadingKpisForDate(false);
      });
    TourService.getStopsPerHour(kpiDate, moment(kpiDate).endOf('day').toDate(), filter.carrierName)
      .then(response => response.json())
      .then(response => {
        setKpiDataStopsPerHourForDate(response);
        setBackendLoadingKpisForDate(false);
      }, () => {
        alert(t('errorLoadingKpiData'));
        setBackendLoadingKpisForDate(false);
      });
  }, [filter, kpiDate, t]);

  const loadProblemNotifications = useCallback(() => {
    setBackendLoadingProblemNotifications(true);
    const fromDate = moment(filter.toDate).subtract(7, 'days');
    TourService.getProblemNotifications(filter.carrierName, filter.shipperName, fromDate, filter.toDate)
      .then(response => response.json())
      .then((response) => {
        setProblemNotifications(response);
        setBackendLoadingProblemNotifications(false);
      }, () => {
        alert(t('errorLoadingProblemNotifications'));
        setBackendLoadingProblemNotifications(false);
      })
  }, [filter.carrierName, filter.toDate, filter.shipperName, t]);

  const loadDriverNotifications = useCallback(async () => {
    setBackendLoadingDriverNotifications(true);
    const fromDate = moment(filter.toDate).subtract(7, 'days');
    try {
      const data = await TourService.getDriverNotificationsByFilter(filter.carrierName, filter.shipperName, fromDate, filter.toDate);
      setDriverNotificationsData(data);
      setBackendLoadingDriverNotifications(false)
    } catch (e) {
      setBackendLoadingDriverNotifications(false)
      alert(`${t('errorLoadingDriverNotifications')}: ${e}`)
    }
  }, [filter.carrierName, filter.toDate, filter.shipperName, t]);


  useEffect(() => {
    loadDriverNotifications();
  }, [loadDriverNotifications]);


  useEffect(() => {
    if (microHubOptions.length > 0) {
      loadData();
    }
  }, [filter, loadData, microHubOptions]);

  useEffect(() => {
    loadKpiDataForDate();
  }, [filter.carrierName, kpiDate, loadKpiDataForDate]);

  useEffect(() => {
    loadKpiDataReference();
  }, [filter.carrierName, kpiReferenceDate, loadKpiDataReference]);

  useEffect(() => {
    loadProblemNotifications();
  }, [loadProblemNotifications])

  const updateKpiDates = (date) => {
    const newDate = moment(date).startOf('day').toDate();
    if (newDate.getMonth() !== kpiDate.getMonth()) {
      setKpiReferenceDate({
        ...kpiReferenceDate,
        from: moment(newDate).subtract(1, 'month').startOf('month').toDate(),
        to: moment(newDate).subtract(1, 'month').endOf('month').toDate()
      })
    }
    setKpiDate(newDate);
  };

  return (
    <SideNavLayout title={t('dashboard')}>
      <div className={classes.root}>
        <div className={classes.helpWrapper}>
          <GeneralInfoIcon/>
          <RefreshButton
            className={classes.buttonRight}
            refresh={refresh}
            refreshFunc={loadData}
          />
        </div>
        <DashboardFilter
          filter={filter}
          microHubOptions={microHubOptions}
          setFilter={setFilter}
          shipperOptions={shipperOptions}
        />
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={8}
            sm={12}
            xl={8}
            xs={12}
          >
            <StopAndTourCard
              className={classes.component}
              dataMicroHubs={microHubData}
              displayMode={displayModes.carrier}
              isLoading={backendLoadingTours || backendLoadingStops}
              stops={stops}
              tours={tours}
            />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <DriverInfoCard
              className={classes.component}
              isLoading={backendLoadingDriverNotifications}
              messages={driverNotificationsData}
            />
          </Grid>
          <Grid
            item
            lg={8}
            sm={12}
            xl={8}
            xs={12}
          >
            <TimeDispositionCard
              className={classes.component}
              filter={filter}
              isLoading={backendLoadingTours}
              tours={tours}
            />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <ProblemNotificationCard
              className={classes.component}
              displayMode={displayModes.carrier}
              isLoading={backendLoadingProblemNotifications}
              problems={problemNotifications}
            />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <ToursInDeliveryCard
              className={classes.component}
              isLoading={backendLoadingTours}
              tours={tours}
            />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <DeadlineCard
              className={classes.component}
              deadlineData={dataShipperDeadlines && dataShipperDeadlines.shippers ? dataShipperDeadlines.shippers : []}
              displayMode={displayModes.admin}
              error={errorShipperDeadlines}
              filter={filter}
              isLoading={loadingShipperDeadlines}
            />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <KPICard
              className={classes.component}
              isLoading={backendLoadingKpisForDate || backendLoadingKpisReference}
              referenceTimeSpan={kpiReferenceDate}
              selectedDate={kpiDate}
              setSelectedDate={updateKpiDates}
              stopsPerHourReference={kpiDataStopsPerHourReference}
              stopsPerHourSelectedDate={kpiDataStopsPerHourForDate}
              tourPackTimeReference={kpiDataTourPackTimeReference}
              tourPackTimeSelectedDate={kpiDataTourPackTimeForDate}
            />
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
            <MicroHubUtilizationCard
              className={classes.component}
              dataMicroHubPlans={dataMicroHubPlans && dataMicroHubPlans.plans ? dataMicroHubPlans.plans : null}
              displayMode={displayModes.carrier}
              error={errorMicroHubPlans}
              filter={filter}
              isLoading={loadingMicroHubPlans || backendLoadingStops}
              stops={stops}
            />
          </Grid>
        </Grid>
      </div>
    </SideNavLayout>
  );

}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(Dashboard);
