import React, {forwardRef, useState} from 'react';
import classNames from 'classnames'
import {NavLink} from 'react-router-dom'
import {Collapse, Divider, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import styles from './styles'
import {
  AllInboxOutlined as BoxAdministrationIcon,
  AssignmentIndOutlined as BaseDataIcon,
  AssignmentOutlined as OverviewsIcons,
  BuildOutlined as SimulateTourBuildIcon,
  DashboardOutlined as DashboardIcon,
  DeleteOutline as DeletedStopsIcon,
  ExpandLess,
  ExpandMore,
  LocalMallOutlined as OrderOverviewIcon,
  PersonOutline as ProfileIcon,
  PersonPinOutlined as CustomerCatalogIcon,
  SettingsOutlined as AdministrationRiderIcon,
  SettingsOverscanOutlined as AreaSettings,
  SystemUpdateAlt as DataUploadIcon,
  VisibilityOutlined as PreviewToursIcon,
  Equalizer as StatisticsOverviewIcon,
} from '@material-ui/icons'
import {withStyles} from '@material-ui/styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';

const linksInGroups = {
  dashboard: '/dashboard',
  dataUpload: '/dataUpload',
  administration: {
    boxAdministration: '/boxAdministration',
    simulateTourBuilding: '/simulateTourBuilding',
    deletedStops: '/deletedStops',
    customerCatalog: '/customerCatalog',
  },
  overviews: {
    previewTours: '/previewTours',
    orderOverview: '/order',
    statisticsOverview: '/statistics-overview',
  },
  baseData: {
    profile: '/profile',
    areaSelection: '/area'
  },
};

function Sidebar(props) {

  const {classes, className, t} = props;

  const [openAdministration, setOpenAdministration] = useState(false);
  const [openOverviews, setOpenOverviews] = useState(false);
  const [openBaseData, setOpenBaseData] = useState(false);

  if (Object.getOwnPropertyNames(linksInGroups.administration).some(property => linksInGroups.administration[property] === window.location.href.substring(window.location.href.lastIndexOf('/')))) {
    if (!openAdministration) setOpenAdministration(true);
  }
  if (Object.getOwnPropertyNames(linksInGroups.overviews).some(property => linksInGroups.overviews[property] === window.location.href.substring(window.location.href.lastIndexOf('/')))) {
    if (!openOverviews) setOpenOverviews(true);
  }
  if (Object.getOwnPropertyNames(linksInGroups.baseData).some(property => linksInGroups.baseData[property] === window.location.href.substring(window.location.href.lastIndexOf('/')))) {
    if (!openBaseData) setOpenBaseData(true);
  }

  const rootClassName = classNames(classes.root, className);

  const MyNavLink = forwardRef((props, ref) => (
    <NavLink
      innerRef={ref}
      {...props}
    />
  ));

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>

        <img
          alt=""
          height="54px"
          src="/images/logo100px.png"
        />

      </div>
      <Divider className={classes.logoDivider}/>
      <List
        component="div"
        disablePadding
      >
        <ListItem
          activeClassName={classes.activeListItem}
          className={classes.listItem}
          component={MyNavLink}
          to={linksInGroups.dashboard}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DashboardIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('dashboard')}
          />
        </ListItem>
        <ListItem
          activeClassName={classes.activeListItem}
          className={classes.listItem}
          component={MyNavLink}
          to={linksInGroups.dataUpload}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DataUploadIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('dataUpload')}
          />
        </ListItem>
        {/*##########################################################Overviews##################################################################*/}
        <ListItem
          button
          className={classes.menuHeader}
          onClick={() => setOpenOverviews(!openOverviews)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <OverviewsIcons/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('overviews')}
          />
          {openOverviews ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse
          in={openOverviews}
          timeout="auto"
        >
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overviews.previewTours}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <PreviewToursIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('previewTours')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overviews.orderOverview}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <OrderOverviewIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('orderOverview')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overviews.statisticsOverview}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <StatisticsOverviewIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t("statisticsOverview")}
              />
            </ListItem>
          </List>
        </Collapse>
        {/*##########################################################Administration##################################################################*/}
        <ListItem
          button
          className={classes.menuHeader}
          onClick={() => setOpenAdministration(!openAdministration)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <AdministrationRiderIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('administrationRider')}
          />
          {openAdministration ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse
          in={openAdministration}
          timeout="auto"
        >
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.customerCatalog}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <CustomerCatalogIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('customerCatalog')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.boxAdministration}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <BoxAdministrationIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('boxAdministration')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.simulateTourBuilding}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SimulateTourBuildIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('simulateTourBuilding')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.deletedStops}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <DeletedStopsIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('deletedStops')}
              />
            </ListItem>
          </List>
        </Collapse>
        {/*##########################################################Base Data##################################################################*/}
        <ListItem
          button
          className={classes.menuHeader}
          onClick={() => setOpenBaseData(!openBaseData)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <BaseDataIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('account')}
          />
          {openBaseData ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse
          in={openBaseData}
          timeout="auto"
        >
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.baseData.profile}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ProfileIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('profile')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.baseData.areaSelection}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AreaSettings/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('areaSelection')}
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </nav>
  );
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(Sidebar);
