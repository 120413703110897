import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Typography, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import pcGaernerApiService from '../../../../../services/backend/pcGaernerApiService';
import {BoxToShipperMappingTable, PackStationForShipperTable, TourIdForShipperTable} from '../../Components';
import {ShipperQueryService} from '../../../../../services/backend/shipperQueryService';
import {useQuery} from '@apollo/react-hooks';
import {LoadingWrapper} from '../../../../../components';
import {ClientService} from '../../../../../services/client/clientService';
import {clientTypes} from '../../../../../services/client/clientTypes';

const clientShipper = ClientService.getClient(clientTypes.shipper);

function PCGaernterConfiguration(props) {

  const {classes, t, selectedShipper} = props;
  const [backendIsLoadingBoxToShipperMapping, setBackendIsLoadingBoxToShipperMapping] = useState(false);
  const [backendIsLoadingTourIdsForShipper, setBackendIsLoadingTourIdsForShipper] = useState(false);
  const [backendIsLoadingPackStationForShipper, setbackendIsLoadingPackStationForShipper] = useState(false);
  const [boxToShipperMappings, setBoxToShipperMappings] = useState([]);
  const [packStationsForShipper, setPackStationsForShipper] = useState([]);
  const [tourIdsForShipper, setTourIdsForShipper] = useState([]);
  const [boxOptions, setBoxOptions] = useState([]);

  const queryBoxTypes = ShipperQueryService.getBoxTypesByShipperNameAndIdentifierList(selectedShipper, []);
  const {data: dataBoxes} = useQuery(queryBoxTypes, {client: clientShipper, skip: !selectedShipper});


  const loadBoxToShipperMappings = useCallback(async (selectedShipper) => {
    setBackendIsLoadingBoxToShipperMapping(true);
    try {
      const result = await pcGaernerApiService.getBoxToShipperMappings(selectedShipper);
      setBoxToShipperMappings(result);
      setBackendIsLoadingBoxToShipperMapping(false);
    } catch (e) {
      setBackendIsLoadingBoxToShipperMapping(false);
      alert(`${t('errorLoadingBoxToShipperMappings')} ${e}`);
    }
  }, [t]);

  const deleteBoxToShipperMapping = async (mapping) => {
    try {
      await pcGaernerApiService.deleteBoxToShipperMapping(mapping.systemID);
      loadBoxToShipperMappings(selectedShipper);
    } catch (e) {
      alert(`${t('errorDeletingBoxToShipperMappings')} ${e}`);
    }
  };

  const createBoxToShipperMapping = async (mapping) => {
    try {
      await pcGaernerApiService.createBoxToShipperMapping(mapping);
      loadBoxToShipperMappings(selectedShipper);
    } catch (e) {
      alert(`${t('errorCreatingBoxToShipperMappings')} ${e}`);
    }
  };

  const loadTourIdsForShipper = useCallback(async (selectedShipper) => {
    setBackendIsLoadingTourIdsForShipper(true);
    try {
      const result = await pcGaernerApiService.getTourIdsForShipper(selectedShipper);
      setTourIdsForShipper(result);
      setBackendIsLoadingTourIdsForShipper(false);
    } catch (e) {
      setBackendIsLoadingTourIdsForShipper(false);
      alert(`${t('errorLoadingTourIdsForShipper')} ${e}`);
    }
  }, [t]);

  const deleteTourIdForShipper = async (tourIdForShipper) => {
    try {
      await pcGaernerApiService.deleteTourIdForShipper(tourIdForShipper.systemId);
      loadTourIdsForShipper(selectedShipper);
    } catch (e) {
      alert(`${t('errorDeletingTourIdForShipper')} ${e}`);
    }
  };

  const createTourIdForShipper = async (tourIdForShipper) => {
    try {
      await pcGaernerApiService.createTourIdForShipper(tourIdForShipper);
      loadTourIdsForShipper(selectedShipper);
    } catch (e) {
      alert(`${t('errorCreatingTourIdForShipper')} ${e}`);
    }
  };

  const loadPackStationsForShipper = useCallback(async (selectedShipper) => {
    setbackendIsLoadingPackStationForShipper(true);
    try {
      const result = await pcGaernerApiService.getPackStationForShipper(selectedShipper);
      setPackStationsForShipper(result);
      setbackendIsLoadingPackStationForShipper(false);
    } catch (e) {
      setbackendIsLoadingPackStationForShipper(false);
      alert(`${t('errorLoadingPackStationForShipper')} ${e}`);
    }
  }, [t]);

  const deletePackStationForShipper = async (packStationForShipper) => {
    try {
      await pcGaernerApiService.deletePackStationForShipper(packStationForShipper.systemId);
      loadPackStationsForShipper(selectedShipper);
    } catch (e) {
      alert(`${t('errorDeletingPackStationForShipper')} ${e}`);
    }
  };

  const createPackStationForShipper = async (packStationForShipper) => {
    try {
      await pcGaernerApiService.createPackStationForShipper(packStationForShipper);
      loadPackStationsForShipper(selectedShipper);
    } catch (e) {
      alert(`${t('errorCreatingPackStationForShipper')} ${e}`);
    }
  };

  useEffect(() => {
    if (dataBoxes && dataBoxes.boxTypes) {
      const boxTypes = [];
      dataBoxes.boxTypes.forEach(boxType => {
        boxTypes.push(boxType.identifier)
      });
      setBoxOptions(boxTypes);
    }
  }, [dataBoxes]);

  useEffect(() => {
    if (selectedShipper) {
      loadBoxToShipperMappings(selectedShipper);
    }
  }, [selectedShipper, loadBoxToShipperMappings]);

  useEffect(() => {
    if (selectedShipper) {
      loadTourIdsForShipper(selectedShipper);
    }
  }, [selectedShipper, loadTourIdsForShipper]);

  useEffect(() => {
    if (selectedShipper) {
      loadPackStationsForShipper(selectedShipper);
    }
  }, [selectedShipper, loadPackStationsForShipper]);

  return (
    <div className={classes.root}>
      <Fragment>
        <Typography variant={'h4'}>
          {t('boxToShipperMapping')}
        </Typography>
        <br/>
        <LoadingWrapper loading={backendIsLoadingBoxToShipperMapping}>
          <BoxToShipperMappingTable
            boxOptions={boxOptions}
            boxToShipperMappings={boxToShipperMappings && boxToShipperMappings.length > 0 ? boxToShipperMappings : []}
            createBoxToShipperMapping={createBoxToShipperMapping}
            deleteBoxToShipperMapping={deleteBoxToShipperMapping}
            selectedShipper={selectedShipper}
          />
        </LoadingWrapper>
        <br/>
        <Typography variant={'h4'}>
          {t('tourIdsForShipper')}
        </Typography>
        <br/>
        <LoadingWrapper loading={backendIsLoadingTourIdsForShipper}>
          <TourIdForShipperTable
            createTourIdForShipper={createTourIdForShipper}
            deleteTourIdForShipper={deleteTourIdForShipper}
            selectedShipper={selectedShipper}
            tourIdsForShipper={tourIdsForShipper && tourIdsForShipper.length > 0 ? tourIdsForShipper : []}
          />
        </LoadingWrapper>
        <br/>
        <Typography variant={'h4'}>
          {t('packStationForShipper')}
        </Typography>
        <br/>
        <LoadingWrapper loading={backendIsLoadingPackStationForShipper}>
          <PackStationForShipperTable
            createPackStationForShipper={createPackStationForShipper}
            deletePackStationForShipper={deletePackStationForShipper}
            packStationsForShipper={packStationsForShipper && packStationsForShipper.length > 0 ? packStationsForShipper : []}
            selectedShipper={selectedShipper}
          />
        </LoadingWrapper>
      </Fragment>
    </div>
  );

}

PCGaernterConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedShipper: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(PCGaernterConfiguration);
