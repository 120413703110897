import React, {forwardRef, useState} from 'react';
import classNames from 'classnames'
import {NavLink} from 'react-router-dom'
import {Collapse, Divider, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import styles from './styles'

import {
  AccessAlarmOutlined as FinalDeadlineMigrationIcon,
  AllInboxOutlined as BoxAdministrationIcon,
  AssignmentOutlined as OverviewsIcons,
  BuildOutlined as SimulateTourBuildIcon,
  CalendarTodayOutlined as SpecialDeliveryDaysIcon,
  DashboardOutlined as DashboardIcon,
  DeleteOutline as DeletedStopsIcon,
  Equalizer as StatisticsOverviewIcon,
  ExpandLess,
  ExpandMore,
  FlagOutlined as StopOverview,
  GetApp as DownloadAppIcon,
  LowPriority as TourTemplatesIcon,
  MapOutlined as StaticTours,
  MotorcycleOutlined as CarrierOverviewIcon,
  PersonOutline as ProfileIcon,
  PersonPinOutlined as CustomerCatalogIcon,
  Pets as MicroHubOverviewIcon,
  Pets as MicroHubAdministrationIcon,
  SettingsApplicationsOutlined as AdministrationIcon,
  SettingsOutlined as AdministrationRiderIcon,
  SettingsOverscanOutlined as AreaSettings,
  TuneOutlined as ShipperApiConfigIcon,
  TuneOutlined as PcGaertnerConfigIcon,
  VisibilityOutlined as PreviewToursIcon
} from '@material-ui/icons'
import {withStyles} from '@material-ui/styles';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';

// Todo: refactor me to render from this object!
const linksInGroups = {
  dashboard: '/dashboard',
  administration: {
    administration: '/administration',
    simulateTourBuilding: '/simulateTourBuilding',
    microHubAdministration: '/microHubAdministration',
    boxAdministration: '/boxAdministration',
    profile: '/profile',
    areaSelection: '/area',
    shipperApiConfig: '/shipperApiConfig',
    pcGaernterConfig: '/pcGaertnerConfig',
    deletedStops: '/deletedStops',
    tourTemplates: '/tourTemplates',
    specialDeliveryDays: '/specialDeliveryDays',
    customerCatalog: '/customerCatalog',
    finalDeadlineMigration: '/finalDeadlineMigration',
    downloadApp: '/get-app'
  },
  overviews: {
    previewTours: '/previewTours',
    microHubOverView: '/microHubOverview',
    tourOverview: '/tour-overview',
    stopOverview: '/stop-overview',
    carrierOverview: '/carrierOverview',
    statisticsOverview: '/statistics-overview'
  },
};

function Sidebar(props) {

  const {classes, className, t} = props;

  const [openAdministration, setOpenAdministration] = useState(false);
  const [openOverviews, setOpenOverviews] = useState(false);

  if (Object.getOwnPropertyNames(linksInGroups.administration).some(property => linksInGroups.administration[property] === window.location.href.substring(window.location.href.lastIndexOf('/')))) {
    if (!openAdministration) setOpenAdministration(true);
  }
  if (Object.getOwnPropertyNames(linksInGroups.overviews).some(property => linksInGroups.overviews[property] === window.location.href.substring(window.location.href.lastIndexOf('/')))) {
    if (!openOverviews) setOpenOverviews(true);
  }

  const rootClassName = classNames(classes.root, className);

  const MyNavLink = forwardRef((props, ref) => (
    <NavLink
      innerRef={ref}
      {...props}
    />
  ));

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>
        <img
          alt=""
          height="54px"
          src="/images/logo100px.png"
        />
      </div>
      <Divider className={classes.logoDivider}/>
      <List
        component="div"
        disablePadding
      >
        <ListItem
          activeClassName={classes.activeListItem}
          className={classes.listItem}
          component={MyNavLink}
          to={linksInGroups.dashboard}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DashboardIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('dashboard')}
          />
        </ListItem>
        {/*##########################################################Overviews##################################################################*/}
        <ListItem
          button
          className={classes.menuHeader}
          onClick={() => setOpenOverviews(!openOverviews)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <OverviewsIcons/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('overviews')}
          />
          {openOverviews ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse
          in={openOverviews}
          timeout="auto"
        >
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overviews.carrierOverview}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <CarrierOverviewIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('carrierOverview')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overviews.previewTours}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <PreviewToursIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('previewTours')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overviews.microHubOverView}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <MicroHubOverviewIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('microHubOverview')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overviews.tourOverview}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <StaticTours/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('tourOverview')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overviews.stopOverview}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <StopOverview/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('stopOverview')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.overviews.statisticsOverview}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <StatisticsOverviewIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('statisticsOverview')}
              />
            </ListItem>
          </List>
        </Collapse>
        {/*##########################################################Administration##################################################################*/}
        <ListItem
          button
          className={classes.menuHeader}
          onClick={() => setOpenAdministration(!openAdministration)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <AdministrationRiderIcon/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary={t('administrationRider')}
          />
          {openAdministration ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse
          in={openAdministration}
          timeout="auto"
        >
          <List
            component="div"
            disablePadding
          >
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.profile}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ProfileIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('account')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.areaSelection}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AreaSettings/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('areaSelection')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.microHubAdministration}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <MicroHubAdministrationIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('microHubAdministration')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.customerCatalog}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <CustomerCatalogIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('customerCatalog')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.tourTemplates}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <TourTemplatesIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('tourTemplates')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.specialDeliveryDays}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SpecialDeliveryDaysIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('specialDeliveryDays')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.boxAdministration}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <BoxAdministrationIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('boxAdministration')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.finalDeadlineMigration}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <FinalDeadlineMigrationIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('finalDeadlineMigration')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.shipperApiConfig}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ShipperApiConfigIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('shipperApiConfig')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.pcGaernterConfig}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <PcGaertnerConfigIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('pcGaertnerConfig')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.simulateTourBuilding}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SimulateTourBuildIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('simulateTourBuilding')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.deletedStops}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <DeletedStopsIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('deletedStops')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.administration}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AdministrationIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('administration')}
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classNames(classes.listItem, classes.nested)}
              component={MyNavLink}
              to={linksInGroups.administration.downloadApp}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <DownloadAppIcon/>
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.listItemText}}
                primary={t('appDownload')}
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </nav>
  );
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(Sidebar);
