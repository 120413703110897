import React, {useState} from 'react'
import {Button, Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import administrationService from '../../../../services/backend/administrationService';
import {LoadingIndicator} from '../../../../components';
import SchedulerResult from './schedulerResult';

function ScheduleDeadline(props) {

  const {classes, t} = props;

  const [backendIsLoading, setBackendIsLoading] = useState(false);
  const [schedulerResult, setSchedulerResult] = useState(null);
  const [schedulerError, setSchedulerError] = useState(false);
  const [previewSchedulerResult, setPreviewSchedulerResult] = useState(null);
  const [previewSchedulerError, setPreviewSchedulerError] = useState(false);


  const runScheduler = async () => {
    setBackendIsLoading(true);
    try {
      const result = await administrationService.runScheduler();
      setBackendIsLoading(false);
      setSchedulerResult(result);
      setSchedulerError(false);
    } catch (e) {
      setBackendIsLoading(false);
      setSchedulerError(true);
      setSchedulerResult(null);
    }
  };

  const runPreviewScheduler = async () => {
    setBackendIsLoading(true);
    try {
      const result = await administrationService.runPreviewScheduler();
      setBackendIsLoading(false);
      setPreviewSchedulerResult(result);
      setPreviewSchedulerError(false);
    } catch (e) {
      setBackendIsLoading(false);
      setPreviewSchedulerError(true);
      setPreviewSchedulerResult(null);
    }
  };


  if (backendIsLoading) {
    return (
      <div>
        <LoadingIndicator/>
      </div>
    );
  }

  return (
    <div>
      <SchedulerResult
        error={schedulerError}
        result={schedulerResult}
      />
      <Tooltip title={t('runSchedulerInfo')}>
        <Button
          className={classes.button}
          color="primary"
          onClick={runScheduler}
          variant="contained"
        >
          {t('runScheduler')}
        </Button>
      </Tooltip>
      <br/>
      <SchedulerResult
        error={previewSchedulerError}
        result={previewSchedulerResult}
      />
      <Tooltip title={t('runPreviewSchedulerInfo')}>
        <Button
          className={classes.button}
          color="primary"
          onClick={runPreviewScheduler}
          variant="contained"
        >
          {t('runPreviewScheduler')}
        </Button>
      </Tooltip>
    </div>
  );

}

ScheduleDeadline.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(ScheduleDeadline);
