import axios from 'axios';
import {AuthService} from '../auth/authService';

const baseUri = `${process.env.REACT_APP_API_URI}optimizer/TourTemplate`;

const createTourTemplate = async (template) => {
  const response = await axios.post(`${baseUri}`,
    template,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getTourTemplatesByFilter = async (carrierName, microHubName, dayOfWeek, shipperName) => {
  const response = await axios.get(`${baseUri}/${carrierName}/${microHubName}/${dayOfWeek}/${shipperName}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
};

const getAutoCutTourTemplatesForVehicleAndDay = async (carrierName, microHubNames, vehicleLicensePlate) => {
  const response = await axios.put(`${baseUri}/autoCut`, {
    carrier: carrierName,
    vehicleLicensePlate,
    microHubNames
  }, {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
};

const reorderTourTemplates = async (carrierName, microHubName, dayOfWeek, reorderedTemplateIds) => {
  const response = await axios.put(`${baseUri}/reorder-templates/${carrierName}/${microHubName}/${dayOfWeek}`,
    reorderedTemplateIds,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    }
  );
  return response.data;
};

const reorderStopsOnTourTemplates = async (templateId, reorderedStopIds) => {
  const response = await axios.put(`${baseUri}/reorder-stops/${templateId}`,
    reorderedStopIds,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const deleteTourTemplate = async (templateId) => {
  const response = await axios.delete(`${baseUri}/${templateId}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
};

const deleteTourTemplateStopById = async (templateStopId) => {
  const response = await axios.delete(`${baseUri}/stop/${templateStopId}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
};

const addCustomersToTourTemplate = async (templateId, tourTemplateCustomersToAdd) => {
  const response = await axios.put(`${baseUri}/add-customers/${templateId}`,
    {newCustomers: tourTemplateCustomersToAdd},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const addCustomersToTourTemplateInClickOrder = async (templateId, tourTemplateCustomersToAdd) => {
  const response = await axios.put(`${baseUri}/add-customers/inClickOrder/${templateId}`,
    {newCustomers: tourTemplateCustomersToAdd},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const updateTourTemplate = async (templateId, tourTemplate) => {
  const response = await axios.put(`${baseUri}/update/${templateId}`,
    tourTemplate,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const duplicateTourTemplate = async (tourTemplateId) => {
  const response = await axios.put(`${baseUri}/clone/${tourTemplateId}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const moveStopToTemplate = async (stopId, targetTemplateId) => {
  const response = await axios.put(`${baseUri}/move-stop/${stopId}/${targetTemplateId}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

export default {
  addCustomersToTourTemplateInClickOrder,
  createTourTemplate,
  updateTourTemplate,
  addCustomersToTourTemplate,
  deleteTourTemplateStopById,
  reorderStopsOnTourTemplates,
  reorderTourTemplates,
  getTourTemplatesByFilter,
  deleteTourTemplate,
  duplicateTourTemplate,
  moveStopToTemplate,
  getAutoCutTourTemplatesForVehicleAndDay
};
