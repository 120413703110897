import {AuthService} from '../auth/authService';
import axios from 'axios';

const algorithmBaseUri = `${process.env.REACT_APP_API_URI}routing`;
const previewBaseUri = `${process.env.REACT_APP_API_URI}preview`;

const runAlgorithm = async (dateToRun, shipperName) => {
  const response = await axios.get(`${algorithmBaseUri}/algorithm/run/${dateToRun}/${shipperName}`,
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const runScheduler = async () => {
  const response = await axios.get(`${algorithmBaseUri}/algorithm/schedule`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const runPreviewScheduler = async () => {
  const response = await axios.get(`${previewBaseUri}/schedule/schedule`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const runAlgorithmForCarrierStops = async (dateToRun, stopIds, doNotMixShippersOnTour, useAlgorithm, useTourTemplates) => {
  const date = new Date(Date.UTC(new Date(dateToRun).getFullYear(), new Date(dateToRun).getMonth(), new Date(dateToRun).getDate())).toISOString();
  const response = await axios.put(`${algorithmBaseUri}/algorithm/runForCarrierStops/${date}`,
    {
      stopIds: stopIds,
      doNotMixShippersOnTour: doNotMixShippersOnTour,
      useAlgorithm: useAlgorithm,
      useTourTemplates: useTourTemplates
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getFinalStopDeadline = async () => {
  const response = await axios.get(`${algorithmBaseUri}/TourEditDeadline`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: AuthService.getAuthHeader()
    }
  });
  return response.data;
};

const updateFinalStopDeadline = async (seconds) => {
  const response = await axios.put(`${algorithmBaseUri}/TourEditDeadline/${seconds}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

export default {runAlgorithm, runScheduler, runPreviewScheduler, runAlgorithmForCarrierStops, getFinalStopDeadline, updateFinalStopDeadline};
