import React, {Fragment, useEffect, useState} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {PortletContent, PortletHeader, StopIdLabel} from '../../../../components';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import StopEditShipper from '../../StopEdit/Shipper';
import StopEditCarrierRestricted from '../../StopEdit/CarrierRestricted';
import {displayModes} from '../../../../services/enums/displayModes';
import {ShipperAllowedOnHubService} from '../../../../services/util/shipperAllowedOnHubHelper';
import StopEditAdmin from '../../StopEdit/Admin';
import {useStopEditTableItems} from './useStopEditTableItems';

function StopDetailEdit(props) {

  const {
    classes,
    className,
    editedStop,
    setEditMode,
    t,
    updateStop,
    setEditedStop,
    editDisplayMode,
    microHubSelectOptions,
    shipperEmptiesSelectOptions,
    initialStop
  } = props;

  const rootClassName = classNames(classes.root, className);

  const stopEditTableHook = useStopEditTableItems(editedStop);
  const [productsReadyForUpdate, setProductsReadyForUpdate] = useState(false);

  useEffect(() => {
    if (productsReadyForUpdate) {
      updateStop();
    }
  }, [productsReadyForUpdate, updateStop])

  const onUpdate = () => {
    setEditedStop({
      ...editedStop,
      products: [...stopEditTableHook.existingProducts, ...stopEditTableHook.newProducts],
      packageInserts: [...stopEditTableHook.existingPackageInserts, ...stopEditTableHook.newPackageInserts],
      empties: [...stopEditTableHook.existingEmpties, ...stopEditTableHook.newEmpties]
    });
    stopEditTableHook.resetItems();
    // handle update via this callback, since the setEditedStop called above has to be processed before the update is written!
    setProductsReadyForUpdate(true);
  };

  const onCancel = () => {
    stopEditTableHook.resetItems();
    setEditMode(false)
  }


  const isSaveDisabled = () => {
    return (
      !editedStop.address.streetAndNumber || !editedStop.address.city || !editedStop.address.zipcode || !editedStop.address.country ||
      !editedStop.lastName || !editedStop.shipperName || !editedStop.planedDeliveryDateShipper ||
      ((initialStop.assignedMicroHubName !== editedStop.assignedMicroHubName || initialStop.shipperName !== editedStop.shipperName) &&
        !ShipperAllowedOnHubService.checkShipperAllowedStop(microHubSelectOptions.find(hub => hub.name === editedStop.assignedMicroHubName), editedStop, stop => stop.planedDeliveryDateShipper)
      ) ||
      !stopEditTableHook.areAllValid()
    );
  }

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{t('tourStopId')}: <StopIdLabel stop={editedStop}/></strong></div>
          <div>
            <Button
              className={classes.button}
              color="default"
              onClick={onCancel}
              title={t('stopCancel')}
              variant="contained"
            >
              <CancelIcon/>&nbsp;{t('cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              disabled={isSaveDisabled()}
              onClick={onUpdate}
              title={t('stopSave')}
              variant="contained"
            >
              <SaveIcon/>
            </Button>
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        {(() => {
          switch (editDisplayMode) {
            case displayModes.admin:
              return (
                <StopEditAdmin
                  className={classes.wrapper}
                  editedStop={editedStop}
                  microHubOptions={microHubSelectOptions}
                  setEditedStop={setEditedStop}
                  shipperEmptiesSelectOptions={shipperEmptiesSelectOptions}
                  stopEditTableHook={stopEditTableHook}
                />
              );
            case displayModes.shipper:
              return (
                <StopEditShipper
                  className={classes.wrapper}
                  editedStop={editedStop}
                  setEditedStop={setEditedStop}
                />);
            case displayModes.carrier:
              return (
                <StopEditCarrierRestricted
                  className={classes.wrapper}
                  editedStop={editedStop}
                  setEditedStop={setEditedStop}
                  shipperEmptiesSelectOptions={shipperEmptiesSelectOptions}
                  stopEditTableHook={stopEditTableHook}
                />);
            default :
              return (
                <StopEditCarrierRestricted
                  className={classes.wrapper}
                  editedStop={editedStop}
                  setEditedStop={setEditedStop}
                  shipperEmptiesSelectOptions={shipperEmptiesSelectOptions}
                  stopEditTableHook={stopEditTableHook}
                />);
          }
        })()
        }
      </PortletContent>
    </Fragment>
  );
}

StopDetailEdit.default = {
  microHubSelectOptions: [],
};


StopDetailEdit.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  editDisplayMode: PropTypes.string.isRequired,
  editedStop: PropTypes.object.isRequired,
  initialStop: PropTypes.object.isRequired,
  microHubSelectOptions: PropTypes.array,
  setEditMode: PropTypes.func.isRequired,
  setEditedStop: PropTypes.func.isRequired,
  shipperEmptiesSelectOptions: PropTypes.array,
  t: PropTypes.func.isRequired,
  updateStop: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(StopDetailEdit);
