import React, {useState} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {InfoIcon, Portlet, PortletContent} from '../../../index';
import {Cancel as CancelIcon, Save as SaveIcon} from '@material-ui/icons';
import {onCloseWithBackdropCurry} from '../../../../services/util/helperFuncs';
import {EmptiesPaper, TrackingLinkInfoEdit, TrackingNotificationsEdit} from '../index';
import {CreateAddress, CreatePerson} from '../../Shared';
import StopBonusEdit from '../StopBonus/Edit';

function CreateShipperDialog(props) {

  const {classes, t, onCancel, onCreate, CreateShipperDialogState} = props;

  const [info, setInfo] = useState({
    name: '',
    signature: false,
    showEmergencyContact: false,
    shortName: '',
  });

  const [empties, setEmpties] = useState([]);

  const [address, setAddress] = useState({
    street: '',
    houseNumber: '',
    zipcode: '',
    city: '',
    country: '',
  });

  const [billingAddress, setBillingAddress] = useState({
    street: '',
    houseNumber: '',
    zipcode: '',
    city: '',
    country: '',
  });

  const [contact, setContact] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  const [serviceContact, setServiceContact] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  const [emergencyContact, setEmergencyContact] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  const [disponent, setDisponent] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  const [deadlines, setDeadlines] = useState({
    previewDeadline: '',
    finalDeadline: '',
  });

  const [trackingNotifications, setTrackingNotifications] = useState({
    emailNotificationsDefaultValue: false,
    senderEmail: '@grünestadtlogistik.com',
    previewStopUploadedNotifications: false,
    previewDeadlineExpiredNotifications: false,
    finalDeadlineExpiredNotifications: false,
    tourApprovedNotifications: false,
    tourStartedNotifications: false,
    previousStopsDeliveredNotifications: false,
    deliveredNotifications: false,
    problemNotifications: false,
    problemNotificationsForShipper: false,
    problemNotificationText: '',
  });

  const [trackingLinkInfos, setTrackingLinkInfos] = useState({
    logoName: '',
    marketingImageName: '',
    primaryColor: '',
    primaryTextColor: '',
    primaryContrastTextColor: '',
    secondaryColor: '',
    secondaryTextColor: '',
  });

  const [stopBonus, setStopBonus] = useState({
    enabled: false,
    threshold: 0,
    divider: 0
  })

  const handleClose = () => {
    onCancel();
  };

  const activateOk = () => {
    return info.name && info.shortName && info.shortName.length <= 3 && address.street && address.houseNumber && address.zipcode && address.city && address.country &&
      billingAddress.street && billingAddress.houseNumber && billingAddress.zipcode && billingAddress.city && billingAddress.country &&
      contact.email && contact.phoneNumber && deadlines.previewDeadline !== '' && deadlines.finalDeadline !== '' && !isNaN(deadlines.previewDeadline) &&
      !isNaN(deadlines.finalDeadline) && parseInt(deadlines.previewDeadline) <= parseInt(deadlines.finalDeadline) && trackingNotifications.senderEmail.split('@')[0];
  };

  const handleCreate = () => {
    onCreate(info, empties, address, billingAddress, contact, serviceContact, emergencyContact, disponent, deadlines, trackingNotifications, trackingLinkInfos, stopBonus);
    setAddress({street: '', houseNumber: '', zipcode: '', city: '', country: '',});
    setBillingAddress({street: '', houseNumber: '', zipcode: '', city: '', country: '',});
    setContact({name: '', email: '', phoneNumber: '',});
    setServiceContact({name: '', email: '', phoneNumber: '',});
    setEmergencyContact({name: '', email: '', phoneNumber: '',})
    setDisponent({name: '', email: '', phoneNumber: '',})
    setInfo({name: '', signature: false, showEmergencyContact: false, shortName: ''});
    setEmpties([]);
    setDeadlines({previewDeadline: '', finalDeadline: ''});
    setStopBonus({enabled: false, threshold: 0, divider: 0});
    setTrackingNotifications({
      senderEmail: '@grünestadtlogistik.com',
      previewStopUploadedNotifications: false,
      previewDeadlineExpiredNotifications: false,
      finalDeadlineExpiredNotifications: false,
      tourApprovedNotifications: false,
      tourStartedNotifications: false,
      previousStopsDeliveredNotifications: false,
      deliveredNotifications: false,
      problemNotifications: false,
      problemNotificationsForShipper: false,
      problemNotificationText: '',
    });
    setTrackingLinkInfos({
      logoName: '',
      marketingImageName: '',
      primaryColor: '',
      primaryTextColor: '',
      primaryContrastTextColor: '',
      secondaryColor: '',
      secondaryTextColor: '',
    })
  };

  return (
    <React.Fragment>
      <Dialog
        disableEscapeKeyDown
        maxWidth={'md'}
        onClose={onCloseWithBackdropCurry(handleClose)}
        open={CreateShipperDialogState.open}
      >
        <DialogTitle id="simple-dialog-title">{t('createShipper')}</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}
              >
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="name"
                    required
                  >{t('name')}</InputLabel>
                  <Input
                    error={!info.name}
                    id="name"
                    onChange={(event) => {
                      setInfo({...info, name: event.target.value});
                    }}
                    required
                    value={info.name}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="shortName"
                    required
                  >{t('shortName')}</InputLabel>
                  <Input
                    error={!info.shortName || info.shortName.length > 3}
                    id="shortName"
                    onChange={(event) => {
                      setInfo({...info, shortName: event.target.value});
                    }}
                    required
                    value={info.shortName}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Portlet className={classes.content}>
                  <PortletContent>
                    <Typography variant="h3">
                      {t('info')}
                    </Typography>
                    <hr/>
                    <Typography variant="body1">
                      {t('empties')}
                    </Typography>
                    <EmptiesPaper
                      empties={empties}
                      setEmpties={setEmpties}
                    />
                    <FormControl className={classes.formControl}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={info.signature}
                              color="primary"
                              onChange={(event) => {
                                setInfo({...info, signature: event.target.checked})
                              }}
                              value={info.signature}
                            />
                          }
                          label={t('signature')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={info.showEmergencyContact}
                              color="primary"
                              onChange={(event) => {
                                setInfo({...info, showEmergencyContact: event.target.checked})
                              }}
                              value={info.showEmergencyContact}
                            />
                          }
                          label={t('showEmergencyContact')}
                        />
                      </FormGroup>
                    </FormControl>
                  </PortletContent>
                </Portlet>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreateAddress
                  address={address}
                  className={classes.content}
                  required
                  setAddress={setAddress}
                  title={t('address')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreateAddress
                  address={billingAddress}
                  className={classes.content}
                  required
                  setAddress={setBillingAddress}
                  title={t('billingAddress')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreatePerson
                  className={classes.content}
                  person={contact}
                  required
                  setPerson={setContact}
                  title={t('contact')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreatePerson
                  className={classes.content}
                  person={emergencyContact}
                  setPerson={setEmergencyContact}
                  title={t('emergencyContact')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreatePerson
                  className={classes.content}
                  person={serviceContact}
                  setPerson={setServiceContact}
                  title={t('serviceContact')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <CreatePerson
                  className={classes.content}
                  person={disponent}
                  setPerson={setDisponent}
                  title={t('disponent')}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <Portlet className={classes.content}>
                  <PortletContent>
                    <div className={classes.iconWrapper}>
                      <Typography variant="h3">
                        {t('deadlines')}
                      </Typography>
                      <InfoIcon
                        className={classes.iconMargin}
                        descriptionKey={'32'}
                      />
                    </div>
                    <hr/>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        htmlFor="previewDeadline"
                        required
                      >{t('previewDeadline')}</InputLabel>
                      <Input
                        error={deadlines.previewDeadline === '' || parseInt(deadlines.previewDeadline) > parseInt(deadlines.finalDeadline)}
                        onChange={(event) => {
                          setDeadlines({
                            ...deadlines,
                            previewDeadline: event.target.value
                          });
                        }}
                        onClick={event => event.target.select()}
                        onWheel={event => event.target.blur()}
                        type="number"
                        value={deadlines.previewDeadline}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        htmlFor="finalDeadline"
                        required
                      >{t('finalDeadline')}</InputLabel>
                      <Input
                        error={deadlines.finalDeadline === '' || parseInt(deadlines.previewDeadline) > parseInt(deadlines.finalDeadline)}
                        onChange={(event) => {
                          setDeadlines({
                            ...deadlines,
                            finalDeadline: event.target.value
                          })
                        }}
                        onClick={event => event.target.select()}
                        onWheel={event => event.target.blur()}
                        type="number"
                        value={deadlines.finalDeadline}
                      />
                    </FormControl>
                  </PortletContent>
                </Portlet>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <TrackingNotificationsEdit
                  className={classes.content}
                  setTrackingNotifications={setTrackingNotifications}
                  trackingNotifications={trackingNotifications}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <TrackingLinkInfoEdit
                  className={classes.content}
                  setTrackingLinkInfos={setTrackingLinkInfos}
                  trackingLinkInfos={trackingLinkInfos}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <StopBonusEdit
                  className={classes.content}
                  setStopBonus={setStopBonus}
                  stopBonus={stopBonus}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            onClick={handleClose}
          >
            <CancelIcon/>&nbsp;{t('dialogCancel')}
          </Button>
          <Button
            color="primary"
            disabled={!activateOk()}
            onClick={handleCreate}
          >
            <SaveIcon/>&nbsp;{t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

CreateShipperDialog.propTypes = {
  CreateShipperDialogState: PropTypes.object.isRequired,
  classes: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(CreateShipperDialog);
