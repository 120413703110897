import React from 'react'
import {Table, TableBody, TableHead, TableRow, withStyles} from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {
  Portlet,
  PortletContent,
  TableCellWithConfig,
  TableSettings,
  TextPlaceholderWrapper
} from '../../../../../../components';

import useTableConfig from '../../../../../../hooks/useTableConfig';
import DefaultConfig from './config';
import {getTableKey} from '../../../../../../services/util/tableConfigHelper';
import {TableKind} from '../../../../../../services/enums/TableKind';
import Columns from './columns';
import {displayModes} from '../../../../../../services/enums/displayModes';

function CustomersAddTable(props) {

  const {classes, className, t, customers, addOrRemoveCustomer, customersToAdd, selectedTemplate, displayMode} = props;

  const {
    config,
    columnConfigs,
    updateConfig
  } = useTableConfig(
    DefaultConfig,
    displayMode,
    getTableKey(TableKind.CustomerAddTable, displayMode),
  )

  const rootClassName = classNames(classes.root, className);

  return (
    <TextPlaceholderWrapper
      active={!customers?.length}
      text={t('noCustomersAvailable')}
    >
      <div style={{position: 'relative'}}>
        <TableSettings
          updateConfig={updateConfig}
          tableConfig={config}
          columns={Columns}
          buttonOptic
        />
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            <Table
              size={'small'}
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                    >
                      {Columns[columnConfig.columnName].header({t})}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedTemplate && customers.map((customer, index) => {
                  const customerCapacities = customer.weekDays.filter(wd => wd.dayOfWeek === selectedTemplate.dayOfWeek)[0]?.capacities;
                  return (
                    <TableRow
                      className={classNames(classes.tableRow, 'cursorPointer')}
                      hover
                      key={index}
                      onClick={() => addOrRemoveCustomer(customer)}
                      selected={customersToAdd.some(c => c.id === customer.id)}
                    >
                      {columnConfigs.map(columnConfig => (
                        <TableCellWithConfig
                          key={columnConfig.order}
                          config={columnConfig}
                        >
                          {Columns[columnConfig.columnName].body({
                            customer, customerCapacities
                          })}
                        </TableCellWithConfig>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </PortletContent>
        </Portlet>
      </div>
    </TextPlaceholderWrapper>
  );
}

CustomersAddTable.propTypes = {
  addOrRemoveCustomer: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  customersToAdd: PropTypes.array.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(CustomersAddTable);
