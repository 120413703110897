import React, {useState} from 'react'
import {AppBar, Tab, Tabs, withStyles} from '@material-ui/core';
import styles from './styles'
import PropTypes from 'prop-types';
import SideNavLayout from '../../../layouts/SideNavLayout';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {PcGaertnerConfiguration, TourImport} from './Components';
import useShipperOptions from '../../../hooks/useShipperOptions';
import {DropdownSelectSimple} from '../../../components';

const tabStates = {
  configuration: 0,
  tourImport: 1,
}

function PCGaernterConfig(props) {

  const {classes, t} = props;
  const [tabState, setTabState] = useState(tabStates.configuration)
  const {shipperOptions} = useShipperOptions();
  const [selectedShipper, setSelectedShipper] = useState('');


  return (
    <SideNavLayout title={t('pcGaertnerConfig')}>
      <AppBar
        className={classes.appBar}
        color={'secondary'}
        position={'relative'}
      >
        <Tabs
          onChange={(event, newValue) => {
            setTabState(newValue);
          }}
          value={tabState}
        >
          <Tab
            label={t('pcGaertnerConfiguration')}
          />
          <Tab
            label={t('pcGaertnerTourImport')}
          />
        </Tabs>
      </AppBar>
      <div className={classes.root}>
        <DropdownSelectSimple
          error={!selectedShipper}
          name={t('shipper')}
          options={shipperOptions}
          required
          setValue={(value) => setSelectedShipper(value)}
          sort
          value={selectedShipper}
        />
        <br/>
      </div>
      <div>
        {(() => {
          switch (tabState) {
            case tabStates.configuration:
              return (
                <PcGaertnerConfiguration selectedShipper={selectedShipper}/>
              );
            case tabStates.tourImport:
              return (
                <TourImport selectedShipper={selectedShipper}/>
              );
            default:
              return (<div>Error</div>);
          }
        })()}
      </div>
    </SideNavLayout>
  );

}

PCGaernterConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};


export default compose(withStyles(styles), withTranslation())(PCGaernterConfig);
