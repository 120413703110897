// eslint-disable-next-line no-unused-vars
export default theme => ({
  formControl: {
    width: '250px'
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconMargin: {
    marginLeft: '8px'
  }
});

