import {groupBy} from 'lodash';

const getBoundingBox = (coordinates) => {
  const latMax = Math.max(...coordinates.map(coordinateTuple => coordinateTuple[0]));
  const latMin = Math.min(...coordinates.map(coordinateTuple => coordinateTuple[0]));
  const lngMax = Math.max(...coordinates.map(coordinateTuple => coordinateTuple[1]));
  const lngMin = Math.min(...coordinates.map(coordinateTuple => coordinateTuple[1]));
  if (Math.abs(latMin - latMax) < 0.001 || Math.abs(lngMin - lngMax) < 0.001) {
    return [
      [latMin - 0.001, lngMin - 0.001],
      [latMax + 0.001, lngMax + 0.001]]
  }
  return [
    [latMin, lngMin],
    [latMax, lngMax]]
};

const getBoundingBoxCoords = (polyLines, stops = [], microHubs = []) => {
  let coordinates = [];
  if (polyLines && polyLines.length) {
    coordinates = polyLines.map(line => line.positions).flat();
  }
  if (stops && stops.length) {
    stops.forEach(stop => {
      coordinates.push([stop.address.latitude, stop.address.longitude])
    });
  }
  // Only use microhubs if no other way for getting a bounding box can be found
  if (coordinates.length <= 1 && microHubs?.length) {
    coordinates = coordinates.concat(microHubs.map(hub => [hub.address.lat, hub.address.lng]));
  }
  return coordinates;
};


/*
Calculate a unique key for a stops position to find duplicates to display overlapping stops
In order to account for minor inaccuracies during geocoding (e.g. Schneeglöckchenstr. 20 vs. Schneeglöckchenstraße 20.)
we round coordinates to 4 decimal places before building the key.
 */
const coordsToKey = (latitude, longitude) => {
  const decimalPlaces = 6;
  const latRounded = Math.round(latitude * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  const lngRounded = Math.round(longitude * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

  return `${latRounded}${lngRounded}`;
};

const filterToursForCoords = (tours) => {
  return tours
    .map(tour => ({
      ...tour,
      stops: tour.stops
        .filter(stop => stop.address.latitude && stop.address.longitude)
        .map(stop => ({...stop, key: coordsToKey(stop.address.latitude, stop.address.longitude)}))
        .sort((s1, s2) => (s1.stopNumber > s2.stopNumber) ? 1 : ((s2.stopNumber > s1.stopNumber) ? -1 : 0))
    }))
    .filter(tour => tour.stops.length > 0);
};

const filterMicroHubsForCoords = (microHubs) => {
  return microHubs.filter(hub => hub.address.lng && hub.address.lat);
};

const filterStopsForCoords = (stops) => {
  return stops
    .filter(stop => stop.address.latitude && stop.address.longitude)
    .map(stop => ({...stop, key: coordsToKey(stop.address.latitude, stop.address.longitude)}));
};

const getPolyLines = (tours, microHubs = [],) => {
  return tours.map(tour => {
    const stopCoords = tour.stops.map(stop => [stop.address.latitude, stop.address.longitude]);
    const matchingHubs = microHubs.filter(hub => tour.microHubName === hub.name);
    if (matchingHubs.length > 0) {
      const matchingHub = matchingHubs[0];
      stopCoords.unshift([matchingHub.address.lat, matchingHub.address.lng]);
      stopCoords.push([matchingHub.address.lat, matchingHub.address.lng]);
    }
    return {color: tour.color, positions: stopCoords};
  });
};

const filterTemplatesForCoords = (templates) => {
  return templates
    .map(template => ({
      ...template,
      stops: template.stops
        .filter(stop => stop.customer?.latitude && stop.customer?.longitude)
        .map(stop => ({...stop, key: coordsToKey(stop.customer.latitude, stop.customer.longitude)}))
        .sort((s1, s2) => (s1.stopNumber > s2.stopNumber) ? 1 : ((s2.stopNumber > s1.stopNumber) ? -1 : 0))
    }))
    .filter(tour => tour.stops.length > 0);
};

const getPolyLinesFromTemplates = (templates, microHubs = [],) => {
  return templates.map(template => {
    const stopCoords = template.stops.map(stop => [stop.customer.latitude, stop.customer.longitude]);
    const matchingHubs = microHubs.filter(hub => template.microHub === hub.name);
    if (matchingHubs.length > 0) {
      const matchingHub = matchingHubs[0];
      stopCoords.unshift([matchingHub.address.lat, matchingHub.address.lng]);
      stopCoords.push([matchingHub.address.lat, matchingHub.address.lng]);
    }
    return {color: template.color, positions: stopCoords};
  });
};

const markerTypes = {
  stop: 'stop',
  tour: 'tour',
  toAddStop: 'toAddStop'
};

const getElementsWithSamePosition = (tours, stops, stopsToAdd = []) => {

  let allElements = tours
    .map(tour => tour.stops.map(stop => ({
      key: stop.key,
      type: markerTypes.tour,
      tour: tour,
      stop: stop
    })))
    .flat()
    .concat(
      stops.map(stop => ({
        key: stop.key,
        type: markerTypes.stop,
        stop: stop
      }))
    )
    .concat(
      stopsToAdd.map(stop => ({
        key: stop.key,
        type: markerTypes.toAddStop,
        stop: stop
      })));

  const groups = groupBy(allElements, x => x.key);
  return Object.getOwnPropertyNames(groups).filter(key => groups[key].length > 1).map(key => groups[key]);
};

const filterToursForDuplicate = (tours, duplicateKeys) => {
  return tours
    .map(tour => (
      {
        ...tour,
        stops: tour.stops.filter(s => !duplicateKeys.some(x => x === s.key))
      }
    ));
};

const filterStopsForDuplicate = (stops, duplicateKeys) => {
  return stops.filter(s => !duplicateKeys.some(x => x === s.key));
};

const getNumStopsOnMap = (tours, stops, stopsToAdd = []) => {
  const numStopsOnTour = tours.reduce((a, t) => a + t.stops.length, 0);
  return numStopsOnTour + stops.length + stopsToAdd.length;
};

export {
  getBoundingBox,
  getBoundingBoxCoords,
  filterToursForCoords,
  filterMicroHubsForCoords,
  filterStopsForCoords,
  getPolyLines,
  filterTemplatesForCoords,
  getPolyLinesFromTemplates,
  markerTypes,
  getElementsWithSamePosition,
  filterStopsForDuplicate,
  filterToursForDuplicate,
  getNumStopsOnMap
};
