import {AuthService} from '../auth/authService';
import axios from 'axios';

const baseUri = `${process.env.REACT_APP_API_URI}oeko-box-service`;

const getPcgToursByShipperName = async (shipperName) => {
  const response = await axios.get(`${baseUri}/admin/PcGaernterApiTourImport/tour-data-by-shipper/${shipperName}`,
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
}

const importPcgTourByShipperNameAndId = async (tourId, tourInstanzeId, shipperName) => {
  const response = await axios.post(`${baseUri}/admin/PcGaernterApiTourImport/import/${tourId}/${tourInstanzeId}/${shipperName}`,
    {},
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    })
    .catch(e => {
      throw new Error(e?.response?.data)
    })
  return response.data;
}

const getBoxToShipperMappings = async (shipperName) => {
  const response = await axios.get(`${baseUri}/admin/pcGaertnerApiConfig/boxmapping/${shipperName}/`,
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const createBoxToShipperMapping = async (mappingToCreate) => {
  const response = await axios.post(`${baseUri}/admin/pcGaertnerApiConfig/boxmapping/`,
    mappingToCreate,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const deleteBoxToShipperMapping = async (mappingId) => {
  const response = await axios.delete(`${baseUri}/admin/pcGaertnerApiConfig/boxmapping/${mappingId}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getTourIdsForShipper = async (shipperName) => {
  const response = await axios.get(`${baseUri}/admin/pcGaertnerApiConfig/tourToFetch/${shipperName}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const deleteTourIdForShipper = async (tourId) => {
  const response = await axios.delete(`${baseUri}/admin/pcGaertnerApiConfig/tourToFetch/${tourId}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const createTourIdForShipper = async (tourIdForShipper) => {
  const response = await axios.post(`${baseUri}/admin/pcGaertnerApiConfig/tourToFetch/`,
    tourIdForShipper,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const getPackStationForShipper = async (shipperName) => {
  const response = await axios.get(`${baseUri}/admin/pcGaertnerApiConfig/packstation/${shipperName}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const createPackStationForShipper = async (mappingToCreate) => {
  const response = await axios.post(`${baseUri}/admin/pcGaertnerApiConfig/packstation/`,
    mappingToCreate,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const deletePackStationForShipper = async (mappingId) => {
  const response = await axios.delete(`${baseUri}/admin/pcGaertnerApiConfig/packstation/${mappingId}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: AuthService.getAuthHeader()
      }
    });
  return response.data;
};

const sendPreviewToPcg = async (internalShipperName, deliveryDate) => {
  const date = new Date(deliveryDate).toISOString();
  return  await axios.get(`${baseUri}/admin/PcGaerterApiTest/previewStops/sendToPcGaertner/internal/${internalShipperName}/${date}`,
    {
      headers: {
        authorization: AuthService.getAuthHeader()
      }
    });
};

export default {
  getBoxToShipperMappings,
  createBoxToShipperMapping,
  deleteBoxToShipperMapping,
  getTourIdsForShipper,
  deleteTourIdForShipper,
  createTourIdForShipper,
  getPackStationForShipper,
  createPackStationForShipper,
  deletePackStationForShipper,
  sendPreviewToPcg,
  importPcgTourByShipperNameAndId,
  getPcgToursByShipperName
};
