export const TableKind = {
  StopTable: 'stop_table',
  TourTable: 'tour_table',
  FluentTourTable: 'fluent_tour_table',
  TimeDispositionTourTable: 'time_disposition_tour_table',
  TourStopTable: 'tour_stop_table',
  TourStopTableSmall: 'tour_stop_table_small',
  TourTableSmall: 'tour_table_small',
  CustomerTable: 'customer_table',
  DriverTable: 'driver_table',
  VehicleTable: 'vehicle_table',
  BoxTypeTable: 'box_type_table',
  MicroHubTable: 'micro_hub_table',
  InvoiceTable: 'invoice_table',
  StatisticsTable: 'statistics_table',
  StopAddTable: 'stop_add_table',
  CustomerAddTable: 'customer_add_table',
  TemplateTable: 'template_table',
  TemplateStopTable: 'template_stop_table',
  PcgTourImportTable: 'pcg_tour_import_table',
  SpecialDeliveryDayTable: 'special_delivery_day_table',
};